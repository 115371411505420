import { css } from "emotion";

const labelContainer = css`
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
`;

const divider = css`
  height: 1px;
  background:rgba(240, 240, 240, 1);
`;

const title = css`
  padding-left: 16px;
  padding-top: 12px;
  color: rgba(61,60,60,1);
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
`;

const LoadingContent = css`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoadingCard = css`
  width: 100%;
  padding: 12px 24px 0px 24px;
  display: flex;
  flex-direction: row;
  background: rgba(250, 250, 250, 1);
  margin-bottom: 4px;
`;

const Circle = css`
  height: 36px;
  width: 36px;
  background:rgba(207, 207, 209, 1);
  border-radius: 50%;
`;

const MiddleContentContainer = css`
  display: flex;
  flex-direction: column;
`;

const cardItem = css`
  margin-left: 12px;
  height: 10px;
  background: rgba(207, 207, 209, 1);
  margin-bottom: 11px;
  border-radius: 4px;
`;

const lastContentContainer = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const filterContainer = css`
  display: flex;
  flex-direction:row;
  padding: 12px 18px 12px 16px;
  justify-content: space-between;
`;

const filterButton = css`
  border-radius: 4px;
  padding: 4px 18px 4px 18px;
  border-color: rgba(0, 185, 135, 1);
  color: rgba(0, 185, 135, 1);
  background: white;
  border-width: 1px;
  border-style: solid;
`;

const sortButton = css`
  border-radius: 4px;
  color: rgba(207, 207, 209, 1);
  background: white;
  border-style: none;
`;

const sortType = css`
  border-radius: 4px;
  margin-left: 2px;
  color: rgba(61, 60, 60, 1);
  background: white;
  border-style: none;
`;


const Card = () => {
  return <div className={LoadingCard}>
    <div className={Circle}></div>
    <div className={MiddleContentContainer}>
      <div className={cardItem} style={{ width: 69 }}></div>
      <div className={cardItem} style={{ width: 69 }}></div>
      <div className={cardItem} style={{ width: 156 }}></div>
      <div className={cardItem} style={{ width: 74 }}></div>
    </div>
    <div className={lastContentContainer}>
      <div className={cardItem} style={{ width: 43 }}></div>
      <div className={cardItem} style={{ width: 41 }}></div>
      <div className={cardItem} style={{ width: 33 }}></div>
    </div>
  </div>
}

export const LoadingState = (props: any) => {
  return (
    <div>
      <div className={labelContainer}>
        <span className={title}>Requests</span>
      </div>
      <div className={divider}></div>
      <div className={filterContainer}>
        <button className={filterButton}>Filter</button>
        <button className={sortButton}>Sort:<span className={sortType}>Newest</span></button>
      </div>
      <div className={LoadingContent}>
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
      </div>
    </div>

  );
};