import { ServiceRequest } from "./types";
import axios from "axios";
import { config } from "./config";

const urlSearchParams = new URLSearchParams(window.location.search);

axios.defaults.headers.common["Authorization"] = `${urlSearchParams.get(
  "auth_secret"
)}`;

console.log(config);
export const axiosInstance = axios.create({
  baseURL: config.BACKEND_URL,
});

class HTTP {
  getServiceRequests = async (
    page: number,
    size: number
  ): Promise<[ServiceRequest[], number]> => {
    const { data } = await axiosInstance.get<[ServiceRequest[], number]>(
      `/admin/service-requests?page=${page}&size=${size}`
    );
    return data;
  };
}

export const http = new HTTP();
