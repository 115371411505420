import { ErrorState } from "./components/ErrorState";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoadingState } from "./components/LoadingState";
import React from "react";
import { ServiceRequest } from "./types";
import { ServiceRequestCard } from "./components/ServiceRequestCard";
import { ServicesFilter } from "./components/ServicesFilter";
import { css } from "emotion";
import { useServiceRequests } from "./query";

const appContainer = css`
  display: flex;
  flex-direction: column;
`;
function App() {
  const {
    data: response,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage
  } = useServiceRequests();

  if (error) {
    return <ErrorState />;
  } else if (isLoading || !response) {
    return <LoadingState />;
  }

  const dataLength = response.pages.reduce((previous, current) => previous + current[0].length, 0)

  return (
    <div className={appContainer}>
      <ServicesFilter />
      <InfiniteScroll
        dataLength={dataLength}
        next={() => fetchNextPage()}
        hasMore={hasNextPage ?? false}
        loader={<h4>Loading...</h4>}
      >
        {response.pages.map((page, i) => {
          const serviceRequests = page[0];
          return (
            <React.Fragment key={i}>
              {serviceRequests.map((serviceRequest) => (
                <ServiceRequestCard
                  key={serviceRequest.id}
                  serviceRequest={new ServiceRequest(serviceRequest)}
                />
              ))}
            </React.Fragment>
          );
        })}
      </InfiniteScroll>
    </div>
  );
}

export default App;
