import { http } from "./http";
import { useInfiniteQuery } from "react-query";

const size = 20;

export const useServiceRequests = () => {
  return useInfiniteQuery(
    "service-requests",
    ({ pageParam = 1 }) => {
      console.log('Fetching page: ', pageParam)
      return http.getServiceRequests(pageParam, size);
    },
    {
      getNextPageParam: (lastPage, pages) => {
        const totalResults = lastPage[1];
        const numberOfPages = pages.length + 1;
        if (size * (numberOfPages - 1) >= totalResults) {
          return null;
        }
        return numberOfPages;
      },
    }
  );
};
