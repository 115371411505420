import { css } from "emotion";
import { ReactComponent as ErrorLogo } from "../icons/error.svg";


const labelContainer = css`
  padding: 16px 16px 110px 16px;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const title = css`
  color: rgba(61,60,60,1);
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
`;

const errorContent = css`
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const errorText = css`
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: center;
  color: rgba(0, 185, 135, 1);
`;

const errorSubtext = css`
  margin-top: 30px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: center;
  color: rgba(101, 101, 102, 1);
`;

const errorButton = css`
  padding: 16px 100px 16px 100px;
  margin-top: 229px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18.75px;
  color: white;
  background: rgba(0, 103, 79, 1);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  border: none;
`;



export const ErrorState = () => {
  return (
    <div>
      <div className={labelContainer}>
        <span className={title}>Requests</span>
      </div>
      <div className={errorContent}>
        <ErrorLogo />
        <span className={errorText}>Something went wrong. Sorry about that.</span>
        <span className={errorSubtext}>Couldn't retrieve data or data doesn't exist.</span>
        <button className={errorButton} onClick={() => { window.location.reload(); }}>
          Try again
        </button>
      </div>
    </div>
  );
};