import { ServiceRequestsEnum } from "./enum";
import repairLogo from './icons/repair.svg';
import maintenanceLogo from './icons/maintenance.svg';
import serviceLogo from './icons/service.svg';


export interface Home {
  id: number;
  name: string;
  address: string;
  city: string;
  state: string;
  zipCode: number;
  image?: Array<File>;
  createdAt: Date;
  updatedAt: Date;
  user_id: number;
  numberOfFloors: number;
  numberOfRegularFloors: number;
  hasAttic: boolean;
  hasBasement: boolean;
  }
export interface Requester {
  id: number;
  role: string;
  confirmed: boolean;
  emailConfirmationToken?: number;
  email: string;
  phoneNumber?: number;
  firstName: string;
  lastName: string;
  createdAt: Date;
  updatedAt: Date;
  numberOfHomes: number;
  fullName: string;
}


export interface IServiceRequest {
  id: number;
  type: string;
  status: string;
  requester_id: number;
  home_id: number;
  technician_id?: number;
  totalPrice?: number;
  scheduledStart: Date;
  scheduledEnd?: Date;
  asSoonAsPossible: boolean;
  isAfternoon: boolean;
  description?: string;
  notes?: string;
  images?: File[];
  files?: File[];
  appliance_id?: number;
  recurrence?: string;
  createdAt: Date;
  updatedAt: Date;
  urgent: boolean;
  requester: Requester;
  home: Home;
}

export class ServiceRequest {
  id!: number;
  type!: string;
  status!: string;
  requester_id!: number;
  home_id!: number;
  technician_id?: number;
  totalPrice?: number;
  scheduledStart!: Date;
  scheduledEnd?: Date;
  asSoonAsPossible!: boolean;
  isAfternoon!: boolean;
  description?: string;
  notes?: string;
  images?: Array<File>;
  files?: Array<File>;
  appliance_id?: number;
  recurrence?: string;
  createdAt!: Date;
  updatedAt!: Date;
  urgent!: boolean;
  requester!: Requester;
  home!: Home;
  
  constructor(json: IServiceRequest) {
    Object.assign(this, json)
  }

  get renderServiceIcon() {
    if (this.type === ServiceRequestsEnum.REPAIR) {
        return repairLogo
    } else if (this.type === ServiceRequestsEnum.MAINTENANCE) {
        return maintenanceLogo
    } else {
        return serviceLogo
    }
  }

  get backgroundColor(): string {
    if(this.type === ServiceRequestsEnum.REPAIR){
      return  'rgba(255, 253, 252, 1)';
   } else if ( this.type === ServiceRequestsEnum.MAINTENANCE){
      return  'rgba(252, 255, 254, 1)';
   } else {
      return  'rgba(255, 254, 252, 1)';
   }
  }

  get iconColor(): string {
    if(this.type === ServiceRequestsEnum.REPAIR){
      return  'rgba(255, 242, 235, 1)';
   } else if ( this.type === ServiceRequestsEnum.MAINTENANCE){
      return  'rgba(240, 250, 243, 1)';
   } else {
      return  'rgba(255, 246, 224, 1)';
   }
  }

  get stripeColor(): string {
    if(this.type === ServiceRequestsEnum.REPAIR){
      return  'rgba(255, 177, 133, 1)';
   } else if ( this.type === ServiceRequestsEnum.MAINTENANCE){
      return  'rgba(0, 185, 135, 1)';
   } else {
      return  'rgba(255, 194, 41, 1)';
   }
  }

}



















