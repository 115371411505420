import { ServiceRequest } from "../types";
import { ReactComponent as UrgentLogo } from "../icons/urgent.svg";
import { capFirstLowOthers } from "../utils";
import { config } from "./../config";
import { css } from "emotion";
import { format } from "date-fns";

const serviceDataContainer = css`
  margin-left: 12px;
  margin-bottom: 12px;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
`;

const urgentRow = css`
  margin-top: 12px;
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const serviceUrgentImage = css`
  width: 18px;
  height: 18px;
  object-fit: cover;
`;

const serviceTypeText = css`
  margin-left: 12px;
  font-size: 12px;
  font-weight: 700;
  color: rgba(61, 60, 60, 1);
  line-height: 16.8px;
`;

const userText = css`
  margin-top: 4px;
  margin-left: 12px;
  font-size: 12px;
  font-weight: 700;
  color: rgba(101, 101, 102, 1);
  line-height: 16.8px;
`;

const addressText = css`
  margin-top: 4px;
  margin-left: 12px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(101, 101, 102, 1);
  line-height: 16.8px;
`;

const urgentText = css`
  margin-left: 9px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(61, 60, 60, 1);
  line-height: 16.8px;
`;

const receivedText = css`
  margin-right: 24px;
  font-size: 10px;
  font-weight: 400;
  color: rgba(161, 162, 163, 1);
  line-height: 14px;
  text-align: right;
`;

const timeText = css`
  margin-right: 24px;
  width: 48px;
  font-size: 10px;
  font-weight: 400;
  color: rgba(101, 101, 102, 1);
  line-height: 14px;
  text-align: right;
`;

const serviceCardBackground = css`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  cursor: pointer;
`;

const iconContainer = css`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const serviceStripe = css`
  width: 4px;
`;

interface IServiceRequestCardProps {
  serviceRequest: ServiceRequest;
}

export const ServiceRequestCard = (props: IServiceRequestCardProps) => {
  const { serviceRequest } = props;
  const createdAt = new Date(serviceRequest.createdAt);

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      style={{ textDecoration: "none" }}
      href={`${config.BACKEND_URL}/dashboard/resources/ServiceRequest/records/${serviceRequest.id}/show`}
      target="_blank"
    >
      <div>
        <div
          className={serviceCardBackground}
          style={{ background: serviceRequest.backgroundColor }}
        >
          <div
            className={serviceStripe}
            style={{ background: serviceRequest.stripeColor }}
          ></div>
          <div className={serviceDataContainer}>
            <div
              className={iconContainer}
              style={{ background: serviceRequest.iconColor }}
            >
              <img src={serviceRequest.renderServiceIcon} alt={"Icon"} />
            </div>
          </div>
          <div className={serviceDataContainer} style={{ flex: 1 }}>
            <span className={serviceTypeText}>
              {capFirstLowOthers(serviceRequest.type)}
            </span>
            <span className={userText}>
              {serviceRequest.requester.fullName}
            </span>
            <span className={addressText}>
              {serviceRequest.home.address}. {serviceRequest.home.city}
            </span>
            <span className={addressText}>
              {serviceRequest.home.state} {serviceRequest.home.zipCode}{" "}
            </span>
            {serviceRequest.urgent && (
              <div>
                <div className={urgentRow}>
                  <UrgentLogo className={serviceUrgentImage} />
                  <span className={urgentText}>Urgent</span>
                </div>
              </div>
            )}
          </div>
          <div className={serviceDataContainer}>
            <span className={receivedText}>Received:</span>
            <span className={timeText}>
              {format(createdAt, "hh:mm a M/dd/yy")}
            </span>
          </div>
        </div>
      </div>
    </a>
  );
};
