import { css } from "emotion";

const servicesFilterContainer = css`
  background: white;
`;

const labelContainer = css`
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
`;

const title = css`
  padding-left: 16px;
  padding-top: 12px;
  color: rgba(61,60,60,1);
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
`;

const filterContainer = css`
  display: flex;
  flex-direction:row;
  padding: 12px 18px 12px 16px;
  justify-content: space-between;
`;

const filterButton = css`
  border-radius: 4px;
  padding: 4px 18px 4px 18px;
  border-color: rgba(0, 185, 135, 1);
  color: rgba(0, 185, 135, 1);
  background: white;
  border-width: 1px;
  border-style: solid;
`;

const sortButton = css`
  border-radius: 4px;
  color: rgba(207, 207, 209, 1);
  background: white;
  border-style: none;
`;

const sortType = css`
  border-radius: 4px;
  margin-left: 2px;
  color: rgba(61, 60, 60, 1);
  background: white;
  border-style: none;
`;

const divider = css`
  height: 1px;
  background:rgba(240, 240, 240, 1);
`;



export const ServicesFilter = () => {
  return (
    <div className={servicesFilterContainer}>
      <div className={labelContainer}>
        <span className={title}>Requests</span>
      </div>
      <div className={divider}></div>
      <div className={filterContainer}>
        <button className={filterButton}>Filter</button>
        <button className={sortButton}>Sort:<span className={sortType}>Newest</span></button>
      </div>
    </div>
  );
};